import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSectionWithVideo from "../components/HeroSectionWithVideo";
import Section from "../components/Section";
import BenefitsList from "../components/BenefitsList";
import ContentsList from "../components/ContentsList";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";
import Introduction from "../components/Introduction.js";
import FAQs from "../components/FAQs";
import Pitch from "../components/Pitch.js";
import Famous from "../components/Famous.js";
import Video from "../components/Video";
import CountdownTimer from "../components/CountdownTimer.js";
import FunnelForm from "../components/FunnelForm";
import Signup from "../components/Signup.js";

const OrPaywPageTemplate = ({ data }) => {
  return (
    <>
      <Layout
        seoObject={data.seoObject}
        imageObject={data.headerObject.imageObject}
      >
        <Section
          classNameInnerContainer="space-y-4 sm:space-y-10"
          className="bg-brand-beige-40"
        >
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            whitespace="normal"
            className="m-0 text-center space-y-1 sm:space-y-2"
          />

          <Video videoObject={data.heroObject.video} loading="eager" />
          {data.heroObject.subtitle && (
            <RenderMarkdown
              markdownContent={data.heroObject.subtitle}
              whitespace="normal"
              className="m-0 text-center"
            />
          )}
          <CountdownTimer
            date={data.configObject.date}
            className="my-10"
            classNameDigits="font-semibold"
          />
          <div className="mx-auto w-full max-w-2xl">
            <FunnelForm data={data.heroObject.signupObject} />
          </div>
        </Section>
        <Section>
          <Famous />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.youKnowText} />
        </Section>
        <Section className="bg-brand-beige-40">
          <RenderMarkdown markdownContent={data.imagineText} />
        </Section>
        <Section>
          <Pitch pitchObject={data.pitchObject} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown
            className="text-center"
            markdownContent={`## **Bereit deinen Gedankenkreisen Lebewohl zu sagen?**`}
          />
          <RenderMarkdown
            className="text-center"
            markdownContent={`### Dann melde dich jetzt kostenfrei an und sei **1. Juli 18:00 Uhr bis 3. Juli 19:00 Uhr** dabei! <br/><br/>Aufgrund von gesundheitlichen Gründen handelt es sich um eine Aufzeichnung des letztjährigen Live-Retreats. Unser Team wird das Seminar **live betreuen und Fragen klären.**<br/><br/>Am Ende zahlst du für das Retreat nur das, was es dir Wert war. Ganz einfach!`}
          />
          <Signup signupObject={data.signupObject2} />
        </Section>
        <HeroSectionWithVideo heroObject={data.videoTestimonalsObject} />
        <Section classNameInnerContainer="flex justify-around items-end flex-wrap space-y-5 sm:space-y-10">
          {data.reviewList.map((el) => (
            <PreviewCompatibleImage
              className="max-w-md w-full"
              imageObject={el.imageObject}
              loading="lazy"
            />
          ))}
        </Section>
        <Section className="bg-brand-beige-40">
          <BenefitsList
            benefitsObject={data.benefitsObject}
            classNameIcon="text-brand-green"
          />
        </Section>
        <Section id="anmelden">
          <RenderMarkdown
            className="text-center"
            markdownContent={`## **Bereit für eine Auszeit aus deinem hektischen Alltag?**`}
          />
          <RenderMarkdown
            className="text-center"
            markdownContent={`### Dann melde dich jetzt kostenfrei an und sei **1. Juli 18:00 Uhr bis 3. Juli 19:00 Uhr** dabei! <br/><br/>Aufgrund von gesundheitlichen Gründen handelt es sich um eine Aufzeichnung des letztjährigen Live-Retreats. Unser Team wird das Seminar **live betreuen und Fragen klären.**<br/><br/>Am Ende zahlst du für das Retreat nur das, was es dir Wert war. Ganz einfach!`}
          />
          <FunnelForm
            classNameForm="max-w-3xl mx-auto"
            data={data.signupObject3}
          />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown markdownContent={data.bonusText} />
        </Section>
        <Section
          className="!px-0 !sm:px-5"
          classNameInnerContainer="space-y-10 sm:space-y-20"
        >
          <Introduction introductionObject={data.introductionObject} />
          <ContentsList
            contentsObject={data.teachersContentsObject}
            classNameCard="bg-white"
          />
        </Section>
        <Section className="bg-brand-beige-40">
          <RenderMarkdown markdownContent={data.criteriaText} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown
            className="text-center"
            markdownContent={`## **Bereit zurück zu dir zu finden?**`}
          />
          <RenderMarkdown
            className="text-center"
            markdownContent={`### Dann melde dich jetzt kostenfrei an und sei **1. Juli 18:00 Uhr bis 3. Juli 19:00 Uhr** dabei! <br/><br/>Aufgrund von gesundheitlichen Gründen handelt es sich um eine Aufzeichnung des letztjährigen Live-Retreats. Unser Team wird das Seminar **live betreuen und Fragen klären.**<br/><br/>Am Ende zahlst du für das Retreat nur das, was es dir Wert war. Ganz einfach!`}
          />
          <Signup signupObject={data.signupObject4} />
        </Section>
        <Section className="bg-brand-beige-40">
          <FAQs faqsObject={data.faqsObject} />
        </Section>
        <Section>
          <RenderMarkdown markdownContent={data.finalText} />
        </Section>
        <Section className="bg-brand-mint">
          <RenderMarkdown
            className="text-center"
            markdownContent={`## **Bereit deinen deinen emotionalen Ballast abzuladen?**`}
          />
          <RenderMarkdown
            className="text-center"
            markdownContent={`### Dann melde dich jetzt kostenfrei an und sei **1. Juli 18:00 Uhr bis 3. Juli 19:00 Uhr** dabei! <br/><br/>Aufgrund von gesundheitlichen Gründen handelt es sich um eine Aufzeichnung des letztjährigen Live-Retreats. Unser Team wird das Seminar **live betreuen und Fragen klären.**<br/><br/>Am Ende zahlst du für das Retreat nur das, was es dir Wert war. Ganz einfach!`}
          />
          <FunnelForm
            classNameForm="max-w-3xl mx-auto"
            data={data.signupObject5}
          />
        </Section>
      </Layout>
    </>
  );
};

const OrPaywPage = ({ data }) => {
  return <OrPaywPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default OrPaywPage;

export const pageQuery = graphql`
  query OrPaywPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        configObject {
          date
        }
        heroObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
          signupObject {
            cta
            ctaSubtitle
            subtitle
            title
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            funnelId
            href
          }
        }
        signupObject2 {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        signupObject3 {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        signupObject4 {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        signupObject5 {
          cta
          ctaSubtitle
          subtitle
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          funnelId
          href
        }
        youKnowText
        imagineText
        pitchObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
          benefitsObject {
            title
            list {
              description
              iconName
            }
          }
        }
        afterText
        firstCtaObject {
          linkText
          linkUrl
        }
        videoTestimonalsObject {
          title
          subtitle
          video {
            cloudflareId
            hasGifThumbnail
          }
        }
        reviewList {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        benefitsObject {
          title
          list {
            description
            iconName
          }
        }
        pricingObject {
          title
          pricingBoxLeftObject {
            title
            price
            priceText
            priceDescription
            ctaObject {
              linkText
              linkUrl
            }
          }
          pricingBoxRightObject {
            title
            price
            priceText
            priceDescription
            ctaObject {
              linkText
              linkUrl
            }
          }
        }
        bonusText
        introductionObject {
          introductionText
          imageSubtext
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          signatureImageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        teachersContentsObject {
          title
          list {
            imageObject {
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              alt
            }
            description
          }
        }
        criteriaText
        ctaObject2 {
          linkText
          linkUrl
        }
        faqsObject {
          title
          qAndA {
            question
            answer
          }
        }
        finalText
        ctaObject3 {
          linkText
          linkUrl
        }
      }
    }
  }
`;
